<template>
  <SubsectionNavBottom
    class="section--top"
    parent="Why Digital"
    :sidebar="sidebar"
    :subSections="subSections">
    <router-view></router-view>
  </SubsectionNavBottom>
</template>

<script>
import { defaultMixins, filters } from '@/common/mixins';

import SubsectionNavBottom from '@/layout/SubsectionNavBottom.vue';

export default {
  name: 'WhyDigital',
  mixins: [defaultMixins, filters],
  props: {
    sidebar: Boolean,
  },
  components: {
    SubsectionNavBottom,
  },
  computed: {
    subSections() {
      return [
        {
          label: this.$i18n.t('why-digital.submenu.digital-value'),
          shareLabel: 'Digital Value Proposition',
          url: '',
        },
      ];
    },
  },
};
</script>
