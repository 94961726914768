<template>
  <div class="section--top" v-if="info">
    <div
      class="about-bg active"
      :style="{ backgroundImage: `url(${subSections[currentSubsection].background})`}"></div>

    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title title--about">
          {{ subSections[currentSubsection].headline || subSections[currentSubsection].label }}
        </h2>
      </div>

      <SubsectionNavLeft
        parent="About Us"
        :currentComponent="subSections[currentSubsection]"
        :subSections="subSections"
        @click-subnav="changeTab" >
          <component
            :data="getData"
            :is="subSections[currentSubsection].component" />
      </SubsectionNavLeft>
    </div>

    <ShareButton
      class="share-button--about"
      :shareLink="`${baseURL}/shareable/hp-overview.pdf`"
      shareLabel="About Us"
      :hash="hashify(`About Us`)"
      @share-this="shareThis" />

  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';
import SubsectionNavLeft from '@/layout/SubsectionNavLeft.vue';

import AboutHP from './about/AboutHP.vue';
import HPHeritage from './about/HPHeritage.vue';
import HPToday from './about/HPToday.vue';
import VisionMission from './about/VisionMission.vue';
import Graphics from './about/Graphics.vue';
import Presses from './about/Presses.vue';
import Growth from './about/Growth.vue';

export default {
  name: 'AboutUs',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
    SubsectionNavLeft,
    AboutHP,
    HPHeritage,
    HPToday,
    VisionMission,
    Graphics,
    Presses,
    Growth,
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      currentSubsection: 0,
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/about_us`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  methods: {
    changeTab(payload) {
      this.currentSubsection = payload.id;
    },
  },
  computed: {
    subSections() {
      return [
        {
          id: 0,
          background: 'images/about-us/about-hp.jpg',
          component: 'AboutHP',
          label: this.getData.about_hp_headline,
          shareLabel: 'About HP',
        },
        {
          id: 1,
          background: 'images/about-us/hp-heritage.jpg',
          component: 'HPHeritage',
          label: this.getData.heritage_headline,
          shareLabel: 'HP Heritage',
        },
        {
          id: 2,
          background: 'images/about-us/hp-today.jpg',
          component: 'HPToday',
          label: this.getData.hp_today_headline,
          shareLabel: 'HP Today',
        },
        {
          id: 3,
          background: 'images/about-us/vision-mission.jpg',
          component: 'VisionMission',
          label: this.getData.vision_mission_headline,
          shareLabel: 'Vision & Mission Statements',
        },
        {
          id: 4,
          background: 'images/about-us/hp-graphics.jpg',
          component: 'Graphics',
          label: this.getData.graphics_headline,
          shareLabel: 'HP Graphics',
        },
        {
          id: 5,
          background: 'images/about-us/hp-pagewide.jpg',
          component: 'Presses',
          headline: this.getData.web_presses_headline,
          label: this.getData.web_presses_label,
          shareLabel: 'HP PageWide Web Presses',
        },
        {
          id: 6,
          background: 'images/about-us/hp-growth.jpg',
          component: 'Growth',
          label: this.getData.growth_headline,
          shareLabel: 'HP PageWide Industrial Growth',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/base.scss';

  .section__title {
    padding-left: 330px;
  };
</style>
